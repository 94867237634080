import React from "react";
export default function Example() {
  return (
    <div className="relative py-16 bg-white overflow-hidden lg:px-12">
      <div className=" text-center pb-12">
        <h1 className="text-3xl text-secondary font-bold lg:mt-12">
          CERTUS BOOKKEEPING PRIVACY POLICY
        </h1>
      </div>
      <div className="lg:px-32 lg:mt-12 xs:px-4">
        <h2>Last updated: February 9th 2022</h2>
        <h2 className="font-bold mt-4 text-xl ">INTRODUCTION</h2>
      </div>
      <div className="lg:px-32 xs:px-4 mt-4 text-lg flex space-y-2 flex-col">
        <p>
          This website [www.certusbookkeeping,com] (the “Site”) is operated by
          Certus Bookkeeping [Certus Capital Inc.] (<b>“Certus Bookkeeping”</b>
          ). Certus Bookkeeping is committed to your privacy and protecting it
          through our compliance with this privacy policy (
          <b>“Privacy Policy”</b>). Certus Bookkeeping has developed this
          Privacy Policy in accordance with the the Personal Information
          Protection and Electronic Documents Act (<b>“PIPEDA”</b>) and other
          relevant privacy laws.
        </p>
        <p className="font-bold mt-2">
          Please read this Privacy Policy carefully and review it periodically
          for the latest information about our privacy practices. By visiting
          and using our website, you expressly consent to our collection, use
          and disclosure of your Personal Information in accordance with this
          Privacy Notice. If you do not agree with the practices described in
          this Privacy Policy, please do not provide us with your Personal
          Information or interact with the Site.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">I.SCOPE</h2>
        <p className=" mt-2">
          This Privacy Policy governs our practices to the collection, use and
          disclosure of Personal Information that we collect through visitors
          and users of Site and individuals who otherwise provide us with
          Personal Information (collectively, <b>“Users”</b>).
        </p>
        <h2 className="font-bold mt-4 text-xl underline">II. DEFINITIONS</h2>
        <p className=" mt-2">
          To better understand this Privacy Notice, Certus Bookkeeping has set
          out some basic definitions to use when reading and interpreting it.
        </p>
        <ul className="list-disc px-12">
          <li>
            <i>Collection:</i> the act of gathering, acquiring, recording, or
            obtaining Personal Information from any source, including third
            parties, by any means.
          </li>
          <li>
            <i>Consent: </i>voluntary agreement to the collection, use and
            disclosure of Personal Information for defined purposes. Consent can
            be either express or implied. Express consent can be given orally,
            electronically or in writing, but is always unequivocal and does not
            require any inference on the part of Certus Bookkeeping. Implied
            consent is consent that can reasonably be inferred from the
            circumstances or from an individual’s action or inaction.
          </li>
          <li>
            <i>Disclosure:</i> making Personal Information available to a third
            party for that party’s own use.
          </li>
          <li>
            <i>Personal Information: </i>information about an identifiable
            individual that is recorded in any form, but does not include
            aggregated information or depersonalized that cannot be associated
            with a User.{" "}
          </li>
          <li>
            <i>Transfer: </i>making Personal Information available to a third
            party for processing, storage or otherwise, solely to be used on
            behalf of Certus Bookkeeping.
          </li>
          <li>
            <i>Use:</i> the treatment, handling and management of Personal
            Information by and within Certus Bookkeeping.
          </li>
        </ul>
        <h2 className="font-bold mt-4 text-xl underline">
          III. ACCOUNTABILITY
        </h2>
        <p className=" mt-2">
          Certus Bookkeeping has designated a Privacy Officer to be responsible
          for its compliance with this Privacy Policy and applicable privacy
          legislation. Certus Bookkeeping shall make known, upon request, the
          title of the person or persons designated to oversee Certus
          Bookkeeping 's compliance with this Privacy Policy
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          IV. THE INFORMATION WE COLLECT
        </h2>
        <p className=" mt-2">
          <i>Personal Information</i>
        </p>
        <p>
          We collect Personal Information from you when you communicate with us
          by using our booking form to make an appointment with us, use our
          contact forms to ask a question or request information about our
          company, or otherwise submit Personal Information to us with your
          communications or through our contact form. Personal information may
          also include information containing details as to whether you have
          opened our emails or how you have used our website, if we can
          associate that information with you. If you are a business customer or
          prospective business customer, Personal Information does not include
          your title, business address and business telephone number when we use
          that information to contact you in your business capacity. Further,
          Personal Information does not include information that is aggregated
          or anonymized in such a manner that it cannot be connected to you or
          any other User. Such information by itself or together cannot be
          tracked to a specific individual’s Personal Information.
        </p>
        <p className=" mt-2">
          <i>Non-Personal Information</i>
        </p>
        <p>
          Certus Bookkeeping collects non-Personal Information (<b>“NPI”</b>)
          about Users when they interact with the Site or use our services. For
          example, we collect information about what site referred you to our
          website, your browser type, operating system type and version,
          language, and your Internet Protocol (“IP”) address. We also collect
          information about how you browse through our website, including
          logging the pages you visit and the amount of time you spend on
          certain pages. See the section below entitled
          <i> “How does Certus Bookkeeping collect, use and share NPI?” </i>for
          more information.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          V. HOW DO WE USE PERSONAL INFORMATION
        </h2>
        <p>
          Certus Bookkeeping collects Personal Information for the following
          purposes:
        </p>
        <ul className="list-disc px-12">
          <li>
            to fulfill your requests for information about our company and our
            products and services;
          </li>
          <li>to provide services to you;</li>
          <li>to communicate with you about your requests and inquiries;</li>
          <li>
            to recommend or to offer you other products and services based on
            your inferred interests;
          </li>
          <li>
            to communicate with you regarding customer service issues; and
          </li>
          <li>
            to protect the security or integrity of our website and our
            business.
          </li>
        </ul>
        <h2 className="font-bold mt-4 text-xl underline">
          VI. HOW DOES CERTUS BOOKKEEPING COLLECT, USE AND SHARE NPI?
        </h2>
        <p>
          Certus Bookkeeping uses NPI in an ongoing effort to better understand
          and serve our customers and to improve the content and functionality
          of our Site.
        </p>
        <p>
          In addition, Certus Bookkeeping may use Personal Information, on an
          aggregated de-identified basis, for research purposes to improve our
          Site or services. This aggregated de-identified information does not
          identify any individual and therefore is considered and treated as NPI
          under this Privacy Policy. For instance, Certus Bookkeeping uses NPI
          to monitor traffic and to collect statistical data.
        </p>
        <p>
          No NPI by itself, or together with aggregated Personal Information,
          can be tracked to a specific individual's Personal Information.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">VII. CONSENT</h2>
        <p>
          Certus Bookkeeping respects your privacy and, unless otherwise
          required by law, Certus Bookkeeping will not collect, use or disclose
          your Personal Information without your prior consent. Your consent may
          be expressed or implied. You may expressly give your consent in
          writing, verbally or through any electronic means. In certain
          circumstances, your consent may be implied by your actions. For
          example, providing Certus Bookkeeping Personal Information to make an
          appointment is implied consent to use such information to make an
          appointment with us.
        </p>
        <p>
          Where appropriate, Certus Bookkeeping will generally seek consent for
          the use or disclosure of the information at the time of collection. In
          certain circumstances, consent with respect to use or disclosure may
          be sought after the information has been collected but before use (for
          example, when Certus Bookkeeping wants to use information for a
          purpose other than those identified above). In obtaining consent,
          Certus Bookkeeping will use reasonable efforts to ensure that a User
          is advised of the identified purposes for which Personal Information
          collected will be used or disclosed.
        </p>
        <p>
          The form of consent sought by Certus Bookkeeping may vary, depending
          upon the circumstances and type of information disclosed. In
          determining the appropriate form of consent, Certus Bookkeeping shall
          take into account the sensitivity of the Personal Information and the
          reasonable expectations of Certus Bookkeeping’s Users. Certus
          Bookkeeping will seek express consent when the information is likely
          to be considered sensitive. Implied consent will generally be
          appropriate where the information is less sensitive.{" "}
        </p>
        <p>
          You may withdraw consent at any time, subject to legal or contractual
          restrictions and reasonable notice. In order to withdraw consent, you
          must provide notice to Certus Bookkeeping in writing.{" "}
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          VIII. LIMITING USE, DISCLOSURE AND RETENTION
        </h2>
        <p>
          As noted above, Certus Bookkeeping does not disclose your Personal
          Information to third parties, except as provided for in this Privacy
          Policy.{" "}
        </p>
        <p>
          We may share your Personal Information with our service providers that
          help us with our business operations. For example, we may transfer
          information with service providers who provide us with marketing and
          promotional services. We require our service providers to keep your
          Personal Information secure. In addition, our service providers may
          not use or disclose Personal Information for any purpose other than
          providing the services on our behalf unless we or they have obtained
          your consent.
        </p>
        <p>
          We may transfer any information we have about you in connection with a
          merger or sale (including transfers made as part of insolvency or
          bankruptcy proceedings) involving all or part of our business or as
          part of a corporate reorganization or stock sale or other change in
          corporate control.
        </p>
        <p>
          Subject to the foregoing, only Certus Bookkeeping’s employees, agents,
          contractors, service providers (and their employees and contractors)
          with a business need to know, or whose duties reasonably so require,
          are granted access to Personal Information about you. All such
          employees and contractors will be required as a condition of
          employment to contractually respect the confidentiality of Certus
          Bookkeeping’s Users’ Personal Information.
        </p>
        <p>
          Certus Bookkeeping will retain Personal Information for only as long
          as required to fulfill the identified purposes or as required by law.
          Personal Information that is no longer required to fulfill the
          identified purposes will be destroyed, erased or made anonymous
          according to the guidelines and procedures established by Certus
          Bookkeeping.
        </p>
        <p>
          Certus Bookkeeping will disclose Personal Information without your
          knowledge or consent if Certus Bookkeeping receives an order,
          subpoena, warrant or other legal requirement issued by a court,
          tribunal, regulator or other person with jurisdiction to compel
          disclosure of your Personal Information. If Certus Bookkeeping
          receives a written request from a police officer or other law
          enforcement agency with authority to request access to your Personal
          Information in the course of an actual or potential investigation into
          a breach of a law, Certus Bookkeeping’s policy is to provide the
          requested information.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          IX. COOKIES & OPT-OUT
        </h2>
        <p>
          Certus Bookkeeping uses cookies to enhance Users’ experiences. Users’
          web browsers place cookies on their computers for record-keeping
          purposes and sometimes to track information about them. Cookies help
          Certus Bookkeeping accurately understand how many consumers are
          visiting Certus Bookkeeping’s Site, how often Users visit the Site,
          what content Users are most interested in, and how they prefer to
          interact with Content on the Site. For example, a cookie will allow
          Certus Bookkeeping to remember certain things like your Internet
          Protocol (IP) address (IP addresses are strings of numbers that
          identify senders and receivers of information sent across the
          Internet), your login information, the time and duration of your
          visits, how you navigated through Certus Bookkeeping’s content and
          where you exited the Site. To save you the time of re-entering your
          login information every time you come back to the Site, Certus
          Bookkeeping may link information contained in a cookie to your email
          address. Certus Bookkeeping does not use cookies to retrieve
          information from your device that Certus Bookkeeping did not
          originally send in a cookie.{" "}
        </p>
        <p>
          You may disable cookies using your browser's settings. Please consult
          your browser's help function for information on how to disable
          cookies. Note that if you disable cookies, certain features of Certus
          Bookkeeping’s Site may not function to its fullest potential.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          X. HOW CAN I ACCESS MY PERSONAL INFORMATION
        </h2>
        <p>
          Upon request, Certus Bookkeeping will provide you information
          regarding the existence, use and disclosure of your Personal
          Information and you will be given access to that information. Certus
          Bookkeeping will respond to an application for individual access to
          Personal Information within a reasonable time and at minimal or no
          cost to the individual. An individual may challenge the accuracy and
          completeness of the information and have it amended as appropriate.
        </p>
        <p>
          <b>NOTE:</b> In certain circumstances, Certus Bookkeeping may not be
          able to provide access to all of your Personal Information that it
          holds. Exceptions may include information that is prohibitively costly
          to provide, information that contains references to other individuals,
          information that cannot be disclosed for legal, security or commercial
          proprietary reasons, or information that is subject to
          solicitor-client or litigation privilege. Certus Bookkeeping will
          provide the reasons for denying access upon request.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">XI. SAFEGUARDS</h2>
        <p>
          Certus Bookkeeping protects your Personal Information by security
          safeguards appropriate to the sensitivity of the information. Certus
          Bookkeeping will protect Personal Information against loss or theft,
          as well as unauthorized access, disclosure, copying, use, or
          modification. This includes limiting access of employees to, and the
          use of, your Personal Information through the use of passwords and
          graduated levels of clearance. We do not publish all of our security
          measures online because this may reduce their effectiveness. We take
          physical precautions to ensure that the computer servers on which your
          Personal Information is stored and archived are secure and that access
          to such servers is protected. We educate our employees with respect to
          their obligations to protect your personal information and we require
          any third-party service providers to take comparable steps to ensure
          the protection of any of your personal information that is shared with
          them.
        </p>
        <p>
          Although we take precautions against possible breaches of our security
          systems, no company can fully eliminate the risks of unauthorized
          access to your Personal Information and no website is completely
          secure. We cannot guarantee that unauthorized access, hacking, data
          loss or breaches of our security systems will never occur.
          Accordingly, you should not transmit Personal Information to us using
          our website if you consider that information to be sensitive.{" "}
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          XII. INQUIRIES; CHALLENGING COMPLIANCE
        </h2>
        <p>
          An individual will be able to make inquiries and/or address a
          challenge concerning compliance with this Privacy Policy to Certus
          Bookkeeping's Privacy Officer.
        </p>
        <p>
          Certus Bookkeeping will maintain procedures for addressing and
          responding to all inquiries or complaints about Certus Bookkeeping's
          handling of Personal Information. Certus Bookkeeping shall investigate
          all complaints. All inquiries or complaints involving Massago's
          handling of Personal Information or compliance with this Privacy
          Policy or with PIPEDA shall be directed to Certus Bookkeeping's
          Privacy Officer.{" "}
        </p>
        <p>
          The Privacy Officer will respond to all such inquiries or complaints
          within 14 business days of receipt. The Privacy Officer will make
          reasonable efforts to resolve all such complaints within 30 days of
          receipt of the initial complaint. If Certus Bookkeeping finds a
          complaint to be justified, it will take appropriate measures,
          including, if necessary, amending its policies and procedures.{" "}
        </p>
        <p>
          For more information, please contact Certus Bookkeeping’s Privacy
          Office as follows:{" "}
        </p>
        <p>
          <u>E-mail:</u> info@certus-cap.com
        </p>
        <p>
          <u>Mail:</u>
        </p>
        <p>Certus Bookkeeping </p>
        <p>Privacy Officer </p>
        <p>6 Millbank Ave </p>
        <p>Toronto, ON </p>
        <p>M5P 1S3</p>
        <h2 className="font-bold mt-4 text-xl underline">
          XIII. STORAGE OUTSIDE OF CANADA
        </h2>
        <p>
          Your Personal Information may be used or stored by us or our service
          providers outside of Canada. We require that our service providers
          safeguard your Personal Information. However, if your Personal
          Information is used or stored outside of Canada, it will also be
          subject to the laws of the country in which it is used or stored.{" "}
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          XIV. CHILDREN AND MINORS
        </h2>
        <p>
          The Site is not directed to children under the age of 16 years. Certus
          Bookkeeping will not knowingly collect Personal Information about a
          child under the age of 16. Certus Bookkeeping does not allow minors
          (persons who are under the age of majority in their place of
          residence) to use the services provided on the Site.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">
          XV. EMAIL COMMUNICATIONS
        </h2>
        <p>
          We comply with Canada’s anti-spam legislation (CASL) and we will not
          send you electronic communications in contravention of this law. We
          will ensure that each email includes an opt-out feature and
          instructions on how to unsubscribe if you no longer wish to receive
          future emails from us. You can unsubscribe using the link included in
          the email or by using the contact information set forth below. If you
          do not expressly consent to receiving electronic communications, we
          will only communicate with you for the limited purposes permitted
          under CASL.
        </p>
        <h2 className="font-bold mt-4 text-xl underline">XVI. GENERAL</h2>
        <p>
          Certus Bookkeeping the right to modify and amend this Privacy Policy
          from time to time in its sole discretion without prior notice to
          clarify our practices and to reflect new or different privacy
          practices, such as when we add new services, functionality or features
          to the Site.. Any such amendment(s) will be posted on the Site and
          will be effective as of the date of posting. If we make any material
          changes we will notify you by e-mail (sent to the e-mail address
          specified in your account, if any). You can determine when this
          Privacy Policy was last revised by referring to the date it was “Last
          Updated” above.
        </p>
      </div>
    </div>
  );
}
