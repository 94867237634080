import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <title> Privacy Policy | Certus Bookkeeping</title>
      <meta name="description" content="Our privacy policy for all of our users." />
      <link rel="canonical" href="https://certusbookkeeping.com/privacy" />
    </Helmet>
  );
}
