/* This example requires Tailwind CSS v2.0+ */

import React from "react";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Privacy from "../components/Privacy/Privacy";
import SEO from "../components/SEO/Privacy/SEO"
export default function Example() {
  return (
    <div>
      <Header />
      <SEO />
      <Privacy />
      <Footer />
    </div>
  );
}
